'use client';

import { Box, Skeleton } from '@mui/material';
import React from 'react';

interface Props {
  contentItemsCount: number;
}

export const ContentLoader = ({ contentItemsCount }: Props) => {
  const contentSkeletons = Array.from({ length: contentItemsCount });

  return (
    <Box height="100%" px={{ lg: 6, xs: 2 }} pt={2.5} pb={{ lg: 5, xs: 20 }}>
      {contentSkeletons.map((_, i) => (
        <Box mb={2} key={i}>
          {contentSkeletons.map((__, j) => (
            <Skeleton key={`${i}-${j}`} />
          ))}
        </Box>
      ))}
    </Box>
  );
};
