import { Address } from '~/data';

import { CreateMeteringPointState } from './types';

type SetNameAction = {
  payload: {
    name: string;
  };
  type: 'SET_NAME';
  meteringPointIndex: number;
};

export const SET_NAME: SetNameAction = {
  meteringPointIndex: 0,
  payload: {
    name: '',
  },
  type: 'SET_NAME',
};

type SetMeteringPointIdAction = {
  payload: {
    meteringPointId: string;
  };
  type: 'SET_METERING_POINT_ID';
  meteringPointIndex: number;
};

export const SET_METERING_POINT_ID: SetMeteringPointIdAction = {
  meteringPointIndex: 0,
  payload: {
    meteringPointId: '',
  },
  type: 'SET_METERING_POINT_ID',
};

type SetPreviousUtilityAction = {
  payload: {
    previousUtility: string;
  };
  type: 'SET_PREVIOUS_UTILITY';
  meteringPointIndex: number;
};

export const SET_PREVIOUS_UTILITY: SetPreviousUtilityAction = {
  meteringPointIndex: 0,
  payload: {
    previousUtility: '',
  },
  type: 'SET_PREVIOUS_UTILITY',
};

type SetAddressAction = {
  payload: {
    address: Address;
  };
  type: 'SET_ADDRESS';
  meteringPointIndex: number;
};

export const SET_ADDRESS: SetAddressAction = {
  meteringPointIndex: 0,
  payload: {
    address: {
      addressLine1: '',
      addressLine2: '',
      area: '',
      municipality: '',
      postCode: '',
      region: '',
      settlement: '',
    },
  },
  type: 'SET_ADDRESS',
};

type SetMeteringPointsDataAction = {
  payload: {
    meteringPointsData: CreateMeteringPointState['meteringPointsData'];
  };
  type: 'SET_METERING_POINTS_DATA';
};

export const SET_METERING_POINTS_DATA: SetMeteringPointsDataAction = {
  payload: {
    meteringPointsData: [],
  },
  type: 'SET_METERING_POINTS_DATA',
};

type SetCurrentMeteringPointIndexAction = {
  payload: {
    currentMeteringPointIndex: number;
  };
  type: 'SET_CURRENT_METERING_POINT_INDEX';
};

export const SET_CURRENT_METERING_POINT_INDEX: SetCurrentMeteringPointIndexAction = {
  payload: {
    currentMeteringPointIndex: 0,
  },
  type: 'SET_CURRENT_METERING_POINT_INDEX',
};

export type Action =
  | SetNameAction
  | SetMeteringPointIdAction
  | SetAddressAction
  | SetCurrentMeteringPointIndexAction
  | SetMeteringPointsDataAction
  | SetPreviousUtilityAction;
