"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BillingPlans = exports.ProducerPowerPlanTypes = exports.CustomerCategory = exports.DevelopmentStage = exports.RequestTypes = void 0;
var RequestTypes;
(function (RequestTypes) {
    RequestTypes["switching"] = "switching";
    RequestTypes["onboarding"] = "onboarding";
})(RequestTypes || (exports.RequestTypes = RequestTypes = {}));
var DevelopmentStage;
(function (DevelopmentStage) {
    DevelopmentStage["working"] = "\u0420\u0430\u0431\u043E\u0442\u0435\u0449\u0430 \u0426\u0435\u043D\u0442\u0440\u0430\u043B\u0430";
    DevelopmentStage["new"] = "\u041D\u043E\u0432 \u0412\u0415\u0418 \u043F\u0440\u043E\u0435\u043A\u0442";
    DevelopmentStage["inProgress"] = "\u0426\u0435\u043D\u0442\u0440\u0430\u043B\u0430 \u0432 \u043F\u0440\u043E\u0446\u0435\u0441 \u043D\u0430 \u0438\u0437\u0433\u0440\u0430\u0436\u0434\u0430\u043D\u0435";
})(DevelopmentStage || (exports.DevelopmentStage = DevelopmentStage = {}));
var CustomerCategory;
(function (CustomerCategory) {
    CustomerCategory["retail"] = "Retail";
    CustomerCategory["common"] = "Custom";
    CustomerCategory["zop"] = "ZOP";
})(CustomerCategory || (exports.CustomerCategory = CustomerCategory = {}));
var ProducerPowerPlanTypes;
(function (ProducerPowerPlanTypes) {
    ProducerPowerPlanTypes["solar"] = "Solar power plants (photovoltaic power plants)";
    ProducerPowerPlanTypes["wind"] = "Wind power plants";
    ProducerPowerPlanTypes["hydro"] = "Hydroelectric power plants";
    ProducerPowerPlanTypes["geothermal"] = "Geothermal power plants";
    ProducerPowerPlanTypes["ocean"] = "Ocean power plants";
    ProducerPowerPlanTypes["biomass"] = "Biomass power plants";
    ProducerPowerPlanTypes["solarThermal"] = "Thermal solar power plants";
    ProducerPowerPlanTypes["hydrogen"] = "Hydrogen power plants";
})(ProducerPowerPlanTypes || (exports.ProducerPowerPlanTypes = ProducerPowerPlanTypes = {}));
var BillingPlans;
(function (BillingPlans) {
    BillingPlans["plan1"] = "\u0421\u0432\u043E\u0431\u043E\u0434\u0435\u043D";
    BillingPlans["plan2"] = "\u0417\u0435\u043B\u0435\u043D\u0430 \u0411\u043E\u0440\u0441\u0430";
    BillingPlans["plan3"] = "\u0417\u0435\u043B\u0435\u043D \u0421\u0442\u0430\u043D\u0434\u0430\u0440\u0442\u0435\u043D+";
    BillingPlans["plan4"] = "\u0416\u044A\u043B\u0442\u0430 \u0417\u0435\u043B\u0435\u043D\u0430 \u0411\u043E\u0440\u0441\u0430";
    BillingPlans["plan5"] = "\u0416\u044A\u043B\u0442\u0430 \u0411\u043E\u0440\u0441\u0430 +  \u0410\u0434\u043C\u0438\u043D\u0438\u0441\u0442\u0440\u0430\u0442\u0438\u0432\u043D\u0430 \u0442\u0430\u043A\u0441\u0430 \u043D\u0430 \u0442\u043E\u0447\u043A\u0430";
    BillingPlans["plan6"] = "\u0416\u044A\u043B\u0442\u0430 \u0411\u043E\u0440\u0441\u0430 +  \u0410\u0434\u043C\u0438\u043D\u0438\u0441\u0442\u0440\u0430\u0442\u0438\u0432\u043D\u0430 \u0442\u0430\u043A\u0441\u0430 \u043D\u0430 \u0431\u043B\u043E\u043A 1.0 MWh";
    BillingPlans["plan7"] = "\u0411\u043E\u0440\u0441\u0430 +  \u0410\u0434\u043C\u0438\u043D\u0438\u0441\u0442\u0440\u0430\u0442\u0438\u0432\u043D\u0430 \u0442\u0430\u043A\u0441\u0430 \u043D\u0430 \u0431\u043B\u043E\u043A 5.0 MWh";
    BillingPlans["plan8"] = "\u0421\u0442\u0430\u043D\u0434\u0430\u0440\u0442\u0435\u043D+";
    BillingPlans["plan9"] = "\u0416\u044A\u043B\u0442\u0430 \u0411\u043E\u0440\u0441\u0430 + \u0422\u044A\u0440\u0433\u043E\u0432\u0441\u043A\u0430 \u043D\u0430\u0434\u0431\u0430\u0432\u043A\u0430";
    BillingPlans["plan10"] = "\u0416\u044A\u043B\u0442\u0430 \u0411\u043E\u0440\u0441\u0430 +  \u0410\u0434\u043C\u0438\u043D\u0438\u0441\u0442\u0440\u0430\u0442\u0438\u0432\u043D\u0430 \u0442\u0430\u043A\u0441\u0430 \u043D\u0430 \u0431\u043B\u043E\u043A 5.0 MWh";
    BillingPlans["plan11"] = "\u0411\u043E\u0440\u0441\u0430 + \u0422\u044A\u0440\u0433\u043E\u0432\u0441\u043A\u0430 \u043D\u0430\u0434\u0431\u0430\u0432\u043A\u0430";
    BillingPlans["plan13"] = "\u0417\u0435\u043B\u0435\u043D \u0421\u0432\u043E\u0431\u043E\u0434\u0435\u043D";
    BillingPlans["plan14"] = "\u0411\u043E\u0440\u0441\u0430 - \u0422\u044A\u0440\u0433\u043E\u0432\u0441\u043A\u0430 \u043D\u0430\u0434\u0431\u0430\u0432\u043A\u0430 (\u041F\u0440\u043E\u0438\u0437\u0432\u043E\u0434\u0438\u0442\u0435\u043B\u0438)";
    BillingPlans["plan15"] = "\u0421\u0442\u0430\u043D\u0434\u0430\u0440\u0442\u0435\u043D (\u041F\u0440\u043E\u0438\u0437\u0432\u043E\u0434\u0438\u0442\u0435\u043B\u0438)";
    BillingPlans["plan16"] = "\u0411\u043E\u0440\u0441\u0430 +  \u0410\u0434\u043C\u0438\u043D\u0438\u0441\u0442\u0440\u0430\u0442\u0438\u0432\u043D\u0430 \u0442\u0430\u043A\u0441\u0430 \u043D\u0430 \u0431\u043B\u043E\u043A 1.0 MWh";
    BillingPlans["plan17"] = "\u0417\u0435\u043B\u0435\u043D\u0430 \u0411\u043E\u0440\u0441\u0430 + \u041F\u0440\u043E\u043C\u043E \u041D\u0430\u0434\u0431\u0430\u0432\u043A\u0430";
    BillingPlans["plan18"] = "\u0411\u043E\u0440\u0441\u0430 + \u041F\u0440\u043E\u043C\u043E \u041D\u0430\u0434\u0431\u0430\u0432\u043A\u0430";
    BillingPlans["plan19"] = "\u0421\u0442\u0430\u043D\u0434\u0430\u0440\u0442\u0435\u043D";
    BillingPlans["plan20"] = "\u0417\u0435\u043B\u0435\u043D \u0421\u0442\u0430\u043D\u0434\u0430\u0440\u0442\u0435\u043D";
})(BillingPlans || (exports.BillingPlans = BillingPlans = {}));
