import {
  Configuration,
  GetAllProtocolsV1Request,
  GetInvoicePreviewFileV1OperationRequest,
  GetProtocolV1200Response,
  ProtocolApi,
  SaveInvoiceFileV1Request,
} from '@toki-power/public-api';

import { Page, Protocol } from '../types';

export type ProtocolsRequestOptions = GetAllProtocolsV1Request;

type ProtocolReturnType = {
  getAll: (options: ProtocolsRequestOptions) => Promise<Page<Protocol>>;
  get: (protocolId: string) => Promise<Protocol>;
  getInvoicePreviewFile: (data: GetInvoicePreviewFileV1OperationRequest) => Promise<string>;
  saveInvoiceFile: (data: SaveInvoiceFileV1Request) => Promise<GetProtocolV1200Response>;
};

export const protocolApi = (config: () => Promise<Configuration>): ProtocolReturnType => {
  const api = async () => new ProtocolApi(await config());

  const getAll = async (options: ProtocolsRequestOptions) =>
    (await api()).getAllProtocolsV1({
      page: options.page,
      size: options.size,
    });

  const get = async (protocolId: string) => (await (await api()).getProtocolV1({ protocolId })).data;

  const getInvoicePreviewFile = async ({
    protocolId,
    data: { issuedBy, invoiceNumber },
  }: GetInvoicePreviewFileV1OperationRequest) =>
    (await api()).getInvoicePreviewFileV1({ data: { issuedBy, invoiceNumber }, protocolId });

  const saveInvoiceFile = async ({ protocolId, invoiceFile }: { protocolId: string; invoiceFile: string }) =>
    (await api()).saveInvoiceFileV1({ protocolId, invoiceFile });

  return {
    getAll,
    get,
    getInvoicePreviewFile,
    saveInvoiceFile,
  };
};
