import { datadogRum } from '@datadog/browser-rum';
import logger from 'logger';

const APP_ID = 'd107bb76-1ced-4602-a33f-0de10c3871cd';
const CLIENT_TOKEN = 'pub2eb467e6be5d87c4667e784b0ac8a8d1';

interface Config {
  service: 'onboarding' | 'blender' | 'local';
}

export const initRum = ({ service }: Config) => {
  const isProduction = process.env.NEXT_PUBLIC_ENVIRONMENT === 'production';
  const isDev = process.env.NEXT_PUBLIC_ENVIRONMENT === 'development';
  const isLocal = process.env.NEXT_PUBLIC_ENVIRONMENT === 'local';
  const isDebugMode = process.env.NEXT_PUBLIC_DEBUG_RUM === 'true';

  const shouldInit = isDebugMode || isProduction || isLocal || isDev;

  if (!shouldInit) {
    return;
  }

  const isInit = Boolean(datadogRum.getInternalContext()?.session_id);

  if (isInit) {
    logger.debug('RUM already initialized');
    return;
  }

  datadogRum.init({
    allowedTracingUrls: [
      'https://toki-public-apis-spec-gateway-4uxaycl0.nw.gateway.dev',
      'https://toki-public-apis-spec-gateway-c9q5gy8x.nw.gateway.dev',
      'https://external-api-jmqjjaiqqq-ey.a.run.app',
      'https://external-api-skis32tmna-ey.a.run.app',
    ],
    applicationId: APP_ID,
    clientToken: CLIENT_TOKEN,
    defaultPrivacyLevel: 'mask-user-input',
    enableExperimentalFeatures: ['feature_flags'],
    env: process.env.NEXT_PUBLIC_ENVIRONMENT,
    service,
    sessionReplaySampleRate: 100,
    site: 'datadoghq.eu',
    trackLongTasks: true,
    trackResources: true,
    version: process.env.NEXT_PUBLIC_VERSION,
  });

  datadogRum.startSessionReplayRecording();
};
