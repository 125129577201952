import { InvoiceDataMeteringPoint } from 'types';

import { MeteringPoint } from '~/services/firestore';

import { formatAddress } from '../formatAddress/formatAddress';

export const parseMeteringPointsData = (meteringPoints: InvoiceDataMeteringPoint[]): MeteringPoint[] =>
  meteringPoints.map((meteringPoint) => {
    const { meteringPointId, meteringPointAddress, meteringPointName } = meteringPoint;
    return {
      address: formatAddress(meteringPointAddress),
      meteringPointId: meteringPointId || '',
      name: meteringPointName || '',
    };
  });
