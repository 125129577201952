'use client';

import logger from 'logger';

import { useAuthFetch } from './useAuthFetch';

const extractFileAndBucketName = (url: string) => {
  const bucketRegex = /https:\/\/storage\.cloud\.google\.com\/([^/]+)\//;
  const fileRegex = /(invoices\/.+)/;

  const bucketMatch = url.match(bucketRegex);
  const fileMatch = url.match(fileRegex);

  return {
    bucketName: bucketMatch && bucketMatch[1],
    fileName: fileMatch && fileMatch[1],
  };
};

export const useCloudStorageFile = () => {
  const { request } = useAuthFetch();

  const getFile = async ({ fileUrl }: { fileUrl: string }) => {
    const { fileName, bucketName } = extractFileAndBucketName(fileUrl);

    const response = await request(`/api/cloud-storage-files?fileName=${fileName}&bucketName=${bucketName}`, {
      method: 'GET',
    });

    const fileContent = await response.blob();

    if (!response.ok) {
      logger.error('Failed to fetch file');
    }

    return { fileContent };
  };

  return { getFile };
};
