import { DocumentData } from 'firebase/firestore';
import { Reducer, useReducer } from 'react';
import { BillingPlans } from 'types';

import { Address, CustomerType } from '~/data';

import {
  Action,
  SET_ACCOUNT_MANAGER,
  SET_ADDRESS,
  SET_BILLING_DATA_SOURCE,
  SET_CUSTOMER_BILLING_CONTACT,
  SET_CUSTOMER_CONTACT,
  SET_CUSTOMER_DATA,
  SET_CUSTOMER_TYPE,
  SET_EIK,
  SET_LEGAL_ENTITY_NAME,
  SET_NAME,
  SET_PERSONAL_ID,
  SET_PLAN_ID,
  SET_REPRESENTATIVES,
  SET_USE_ACCOUNT_CONTACT,
} from './actions';
import { stateReducer } from './stateReducer';
import { CreateCustomerState } from './types';

export type Name = {
  firstName: string;
  middleName: string;
  lastName: string;
};

export const DEFAULT_STATE: CreateCustomerState = {
  customerData: {
    accountManager: undefined,
    address: {
      addressLine1: '',
      addressLine2: '',
      area: '',
      municipality: '',
      postCode: '',
      region: '',
      settlement: '',
    },
    billingContact: {
      email: '',
      firstName: '',
      lastName: '',
      phone: {
        country: 'bg',
        countryCode: '+359',
        value: '',
      },
    },
    billingDataSource: null,
    contact: {
      email: '',
      firstName: '',
      lastName: '',
      phone: {
        country: 'bg',
        countryCode: '+359',
        value: '',
      },
    },
    currentRepresentativeIndex: 0,
    customerType: null,
    eik: '',
    firstName: '',
    lastName: '',
    legalEntityName: '',
    middleName: '',
    personalId: '',
    representatives: [],
    useAccountContact: false,
  },
};

interface UseStateManagerOptions {
  onboardingData?: DocumentData | null;
}

export type UseStateManagerReturn = {
  setCustomerType: (customerType: CustomerType) => void;
  setCustomerContact: (contact: CreateCustomerState['customerData']['contact']) => void;
  setCustomerBillingContact: (contact: CreateCustomerState['customerData']['billingContact']) => void;
  setRepresentatives: (
    representatives: CreateCustomerState['customerData']['representatives'],
    representativeIndex: number
  ) => void;
  setName: (name: Name) => void;
  setAddress: (address: Address) => void;
  setPersonalId: (personalId: string) => void;
  setCustomerData: (customerData: CreateCustomerState['customerData']) => void;
  setLegalEntityName: (legalEntityName: string) => void;
  setEIK: (eik: string) => void;
  setCurrentRepresentativeIndex: (currentRepresentativeIndex: number) => void;
  removeRepresentative: (representativeIndex: number) => void;
  state: CreateCustomerState;
  setAccountManager: (accountManager: string) => void;
  setPlan: (plan: BillingPlans) => void;
  setUseAccountContact: (useAccountContact?: boolean) => void;
  setBillingDataSource: (billingDataSource?: string | null) => void;
};

export const useStateManager = ({ onboardingData }: UseStateManagerOptions): UseStateManagerReturn => {
  const onboardingCustomerData = onboardingData?.customerData;
  const [state, dispatch] = useReducer<Reducer<CreateCustomerState, Action>>(stateReducer, {
    customerData: {
      ...DEFAULT_STATE.customerData,
      ...onboardingCustomerData,
    },
  });

  const setAccountManager = (accountManager: string): void => {
    dispatch({ payload: { accountManager }, type: SET_ACCOUNT_MANAGER.type });
  };

  const setPlan = (planId: BillingPlans): void => {
    dispatch({ payload: { planId }, type: SET_PLAN_ID.type });
  };

  const setCustomerType = (customerType: CustomerType): void => {
    dispatch({ payload: { customerType }, type: SET_CUSTOMER_TYPE.type });
  };

  const setCustomerContact = (contact: CreateCustomerState['customerData']['contact']): void => {
    dispatch({ payload: { contact }, type: SET_CUSTOMER_CONTACT.type });
  };

  const setUseAccountContact = (useAccountContact?: boolean): void => {
    dispatch({ payload: { useAccountContact }, type: SET_USE_ACCOUNT_CONTACT.type });
  };

  const setCustomerBillingContact = (billingContact: CreateCustomerState['customerData']['billingContact']): void => {
    dispatch({ payload: { billingContact }, type: SET_CUSTOMER_BILLING_CONTACT.type });
  };

  const setBillingDataSource = (billingDataSource?: string | null): void => {
    dispatch({ payload: { billingDataSource }, type: SET_BILLING_DATA_SOURCE.type });
  };

  const setName = (name: Name): void => {
    dispatch({ payload: name, type: SET_NAME.type });
  };

  const setEIK = (eik: string): void => {
    dispatch({ payload: { eik }, type: SET_EIK.type });
  };

  const setLegalEntityName = (legalEntityName: string): void => {
    dispatch({ payload: { legalEntityName }, type: SET_LEGAL_ENTITY_NAME.type });
  };

  const setPersonalId = (personalId: string): void => {
    dispatch({ payload: { personalId }, type: SET_PERSONAL_ID.type });
  };

  const setAddress = (address: Address): void => {
    dispatch({ payload: { address }, type: SET_ADDRESS.type });
  };

  const setCustomerData = (customerData: CreateCustomerState['customerData']): void => {
    dispatch({ payload: { ...customerData }, type: SET_CUSTOMER_DATA.type });
  };

  const setRepresentatives = (
    representatives: CreateCustomerState['customerData']['representatives'],
    representativeIndex: number
  ): void => {
    dispatch({
      payload: { currentRepresentativeIndex: representativeIndex, representatives },
      type: SET_REPRESENTATIVES.type,
    });
  };

  const setCurrentRepresentativeIndex = (currentRepresentativeIndex: number): void => {
    dispatch({
      payload: { currentRepresentativeIndex, representatives: state.customerData.representatives },
      type: SET_REPRESENTATIVES.type,
    });
  };

  const removeRepresentative = (representativeIndex: number) => {
    const newRepresentatives = [...state.customerData.representatives];
    newRepresentatives.splice(representativeIndex, 1);

    dispatch({
      payload: { currentRepresentativeIndex: representativeIndex, representatives: newRepresentatives },
      type: SET_REPRESENTATIVES.type,
    });
  };

  return {
    removeRepresentative,
    setAccountManager,
    setAddress,
    setBillingDataSource,
    setCurrentRepresentativeIndex,
    setCustomerBillingContact,
    setCustomerContact,
    setCustomerData,
    setCustomerType,
    setEIK,
    setLegalEntityName,
    setName,
    setPersonalId,
    setPlan,
    setRepresentatives,
    setUseAccountContact,
    state,
  };
};
