/* eslint-disable no-inline-styles/no-inline-styles */
import { OnboardingEvents, trackTagManagerEvent } from 'firebase-client';
import {
  Checkbox,
  FormProvider,
  PhoneInput,
  SubmitHandler,
  TextInput,
  useForm,
  validatePhoneNumber,
  yupResolver,
} from 'forms';
import logger from 'logger';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Phone } from 'types';
import { Box, Form } from 'ui';
import { bool, mixed, object, string } from 'yup';

import { useCreateCustomer } from '~/customer';
import { ContinueButton, StepTitle } from '~/ui';

interface FormValues {
  firstName?: string;
  lastName?: string;
  phone: Phone;
  email?: string;
  useAccountContact?: boolean;
}

export const BusinessContactPersonStep = () => {
  const { t } = useTranslation('createCustomerWizard');
  const {
    goToNextStep,
    getCustomerContact,
    setCustomerContact,
    setName,
    navigateWithRedirect,
    getRepresentatives,
    setUseAccountContact,
    getResidentialCustomerContact,
    getCustomerData,
  } = useCreateCustomer();

  const { phone, email, firstName, lastName } = getCustomerContact();

  const representative = getRepresentatives()[0];
  const accountData = getResidentialCustomerContact();

  const { useAccountContact } = getCustomerData();

  const resolver = yupResolver(
    object({
      email: string().when('useAccountContact', {
        is: false,
        then: () => string().required(t('validation:required')).email(t('steps.contact.fields.email.invalid')),
      }),
      firstName: string().when('useAccountContact', {
        is: false,
        then: () => string().required(t('steps.contact.fields.firstName.required')),
      }),
      lastName: string().when('useAccountContact', {
        is: false,
        then: () => string().required(t('steps.contact.fields.lastName.required')),
      }),
      phone: mixed<Phone>()
        .defined()
        .when('useAccountContact', {
          is: false,
          then: () =>
            mixed<Phone>()
              .defined()
              .test('phoneValidation', t('validation:invalidPhone'), ({ country, countryCode, value }) =>
                validatePhoneNumber(country, countryCode, value)
              ),
        }),
      useAccountContact: bool(),
    })
  );

  const { handleSubmit, watch, formState, setValue, resetField, clearErrors, ...rest } = useForm<FormValues>({
    defaultValues: {
      email,
      firstName,
      lastName,
      phone,
      useAccountContact,
    },
    mode: 'onChange',
    resolver,
  });

  const handleUseCustomerContactChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setValue('email', accountData.email);
      setValue('firstName', representative.firstName);
      setValue('lastName', representative.lastName);
      setValue('phone', accountData.phone);
    } else {
      setValue('email', '');
      setValue('firstName', '');
      setValue('lastName', '');
      setValue('phone', { country: 'bg', countryCode: '+359', value: '' });
    }

    clearErrors();
  };

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    trackTagManagerEvent(OnboardingEvents.contactData);

    if (!values.email || !values.firstName || !values.lastName || !values.phone) {
      logger.error('Error while submitting business contact form', { values });

      return;
    }

    setUseAccountContact(values.useAccountContact);

    setCustomerContact({
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone,
    });

    setName({
      firstName: values.firstName,
      lastName: values.lastName,
      middleName: '',
    });

    navigateWithRedirect(goToNextStep);
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name !== 'useAccountContact' && type === 'change' && value.useAccountContact) {
        setValue('useAccountContact', false);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  return (
    <FormProvider {...{ clearErrors, formState, handleSubmit, resetField, setValue, watch, ...rest }}>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box display="flex" flexDirection="column">
          <Box mb={2}>
            <StepTitle>{t('steps.contact.title')}</StepTitle>
          </Box>

          <Checkbox
            name="useAccountContact"
            label={t('steps.contact.fields.useAccountContact.label')}
            data-testid="use-customer-contact"
            onChange={handleUseCustomerContactChange}
          />

          <Box display="flex" flexDirection="column" gap={1}>
            <TextInput
              required
              margin="normal"
              fullWidth
              label={t(`steps.contact.fields.firstName.label`)}
              placeholder={t(`steps.contact.fields.firstName.placeholder`)}
              name="firstName"
              autoFocus
              shrink
              data-testid="first-name"
            />

            <TextInput
              required
              margin="normal"
              fullWidth
              label={t(`steps.contact.fields.lastName.label`)}
              placeholder={t(`steps.contact.fields.lastName.placeholder`)}
              name="lastName"
              shrink
              data-testid="last-name"
            />

            <TextInput
              required
              margin="normal"
              fullWidth
              label={t(`steps.contact.fields.email.label`)}
              placeholder={t(`steps.contact.fields.email.placeholder`)}
              autocomplete="email"
              name="email"
              autoFocus
              shrink
              data-testid="email"
            />

            <PhoneInput
              required
              margin="normal"
              fullWidth
              label={t('steps.contact.fields.phone.placeholder')}
              autocomplete="phone"
              name="phone"
              autoFocus
              shrink
              data-testid="phone"
            />
          </Box>
        </Box>

        <ContinueButton data-testid="business-contact-submit-button" disabled={!formState.isValid} />
      </Form>
    </FormProvider>
  );
};
