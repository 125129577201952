import { Reducer } from 'react';

import {
  Action,
  SET_ADDRESS,
  SET_CURRENT_METERING_POINT_INDEX,
  SET_METERING_POINT_ID,
  SET_METERING_POINTS_DATA,
  SET_NAME,
  SET_PREVIOUS_UTILITY,
} from './actions';
import { CreateMeteringPointState } from './types';

export const stateReducer: Reducer<CreateMeteringPointState, Action> = (prevState, action) => {
  switch (action.type) {
    case SET_NAME.type:
    case SET_ADDRESS.type:
    case SET_METERING_POINT_ID.type:
    case SET_PREVIOUS_UTILITY.type:
      if (!prevState.meteringPointsData[action.meteringPointIndex]) {
        return {
          ...prevState,
          meteringPointsData: [
            ...prevState.meteringPointsData,
            { ...action.payload } as CreateMeteringPointState['meteringPointsData'][number],
          ],
        };
      }

      return {
        ...prevState,
        meteringPointsData: prevState.meteringPointsData.map((meteringPoint, index) => {
          if (index === action.meteringPointIndex) {
            return {
              ...meteringPoint,
              ...action.payload,
            };
          }
          return meteringPoint;
        }),
      };
    case SET_METERING_POINTS_DATA.type:
    case SET_CURRENT_METERING_POINT_INDEX.type:
      return {
        ...prevState,
        ...action.payload,
      };

    default:
      return prevState;
  }
};
