import { Reducer } from 'react';

import {
  Action,
  SET_ACCOUNT_MANAGER,
  SET_ADDRESS,
  SET_BILLING_DATA_SOURCE,
  SET_CUSTOMER_BILLING_CONTACT,
  SET_CUSTOMER_CONTACT,
  SET_CUSTOMER_DATA,
  SET_CUSTOMER_TYPE,
  SET_EIK,
  SET_LEGAL_ENTITY_NAME,
  SET_NAME,
  SET_PERSONAL_ID,
  SET_PLAN_ID,
  SET_REPRESENTATIVES,
  SET_USE_ACCOUNT_CONTACT,
} from './actions';
import { CreateCustomerState } from './types';

export const stateReducer: Reducer<CreateCustomerState, Action> = (prevState, action) => {
  switch (action.type) {
    case SET_CUSTOMER_TYPE.type:
    case SET_REPRESENTATIVES.type:
    case SET_NAME.type:
    case SET_ADDRESS.type:
    case SET_PERSONAL_ID.type:
    case SET_CUSTOMER_DATA.type:
    case SET_LEGAL_ENTITY_NAME.type:
    case SET_CUSTOMER_CONTACT.type:
    case SET_CUSTOMER_BILLING_CONTACT.type:
    case SET_EIK.type:
    case SET_ACCOUNT_MANAGER.type:
    case SET_PLAN_ID.type:
    case SET_BILLING_DATA_SOURCE.type:
    case SET_USE_ACCOUNT_CONTACT.type:
      return {
        ...prevState,
        customerData: {
          ...prevState.customerData,
          ...action.payload,
        },
      };
    default:
      return prevState;
  }
};
