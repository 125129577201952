/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
const locations = ['shared', 'onboarding'];

const i18n = {
  defaultLang: 'bg',
  translations: locations.reduce(
    (acc, location) => {
      const en = require(`./translations/${location}/${location}.en.json`);
      const bg = require(`./translations/${location}/${location}.bg.json`);

      return {
        bg: { ...acc.bg, [location]: bg },
        en: { ...acc.en, [location]: en },
      };
    },
    { bg: {}, en: {} }
  ),
};

module.exports = i18n;
