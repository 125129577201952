/* eslint-disable no-inline-styles/no-inline-styles */
import { OnboardingEvents, trackTagManagerEvent } from 'firebase-client';
import {
  FormProvider,
  PhoneInput,
  RadioButtons,
  SubmitHandler,
  TextInput,
  useForm,
  validatePhoneNumber,
  yupResolver,
} from 'forms';
import logger from 'logger';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Phone } from 'types';
import { Box, Button, Form } from 'ui';
import { mixed, object, string } from 'yup';

import { useCreateCustomer } from '~/customer';
import { StepTitle } from '~/ui';

interface FormValues {
  firstName: string;
  lastName: string;
  phone: Phone;
  email: string;
  billingDataSource?: string | null;
}

export const BillingContactPersonStep = () => {
  const { t } = useTranslation('createCustomerWizard');
  const {
    goToNextStep,
    getCustomerContact,
    setCustomerBillingContact,
    navigateWithRedirect,
    getResidentialCustomerContact,
    getRepresentatives,
    getCustomerBillingContact,
    isCompany,
    getCustomerData,
    setBillingDataSource,
  } = useCreateCustomer();

  const customerContact = getCustomerContact();
  const { billingDataSource, useAccountContact } = getCustomerData();
  const { firstName, email, lastName, phone } = getCustomerBillingContact();

  const representative = getRepresentatives()[0];
  const accountData = getResidentialCustomerContact();

  const customerAccountData = isCompany()
    ? {
        email: accountData.email,
        firstName: representative.firstName,
        lastName: representative.lastName,
        phone: accountData.phone,
      }
    : accountData;

  const resolver = yupResolver(
    object({
      billingDataSource: string().nullable(),
      email: string().required(t('validation:required')).email(t('steps.billingContact.fields.email.invalid')),
      firstName: string().required(t('steps.billingContact.fields.firstName.required')),
      lastName: string().required(t('steps.billingContact.fields.lastName.required')),
      phone: mixed<Phone>()
        .defined()
        .test('phoneValidation', t('validation:invalidPhone'), ({ country, countryCode, value }) =>
          validatePhoneNumber(country, countryCode, value)
        ),
    })
  );

  const { handleSubmit, watch, setValue, resetField, clearErrors, trigger, formState, ...rest } = useForm<FormValues>({
    defaultValues: {
      billingDataSource,
      email,
      firstName,
      lastName,
      phone,
    },
    resolver,
  });

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    trackTagManagerEvent(OnboardingEvents.billingData);

    setBillingDataSource(values.billingDataSource);

    if (values.email && values.firstName && values.lastName && values.phone) {
      setCustomerBillingContact({
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone,
      });

      navigateWithRedirect(goToNextStep);

      return;
    }

    logger.error('Error while submitting billing contact form', { values });
  };

  const handleRadioButtonsChange = (optionValue: string) => {
    if (optionValue === 'customerContact') {
      Object.entries(customerContact).forEach(([key, value]) => setValue(key as keyof typeof customerContact, value));
    } else {
      Object.entries(customerAccountData).forEach(([key, value]) =>
        setValue(key as keyof typeof customerAccountData, value)
      );
    }

    trigger();
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name !== 'billingDataSource' && type === 'change' && value.billingDataSource) {
        setValue('billingDataSource', '');
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  return (
    <FormProvider {...{ clearErrors, trigger, formState, handleSubmit, resetField, setValue, watch, ...rest }}>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box display="flex" flexDirection="column">
          <Box mb={2}>
            <StepTitle>{t('steps.billingContact.title')}</StepTitle>
          </Box>

          <RadioButtons
            name="billingDataSource"
            onChange={handleRadioButtonsChange}
            options={[
              { label: t('steps.billingContact.fields.useAccountData.label'), value: 'accountData' },
              ...(isCompany() && !useAccountContact
                ? [
                    {
                      label: t('steps.billingContact.fields.useAccountContact.label'),
                      value: 'customerContact',
                    },
                  ]
                : []),
            ]}
          />

          <Box display="flex" flexDirection="column" gap={1}>
            <TextInput
              required
              margin="normal"
              fullWidth
              label={t(`steps.billingContact.fields.firstName.label`)}
              placeholder={t(`steps.billingContact.fields.firstName.placeholder`)}
              name="firstName"
              shrink
              data-testid="first-name"
            />

            <TextInput
              required
              margin="normal"
              fullWidth
              label={t(`steps.billingContact.fields.lastName.label`)}
              placeholder={t(`steps.billingContact.fields.lastName.placeholder`)}
              name="lastName"
              shrink
              data-testid="last-name"
            />

            <TextInput
              required
              margin="normal"
              fullWidth
              label={t(`steps.billingContact.fields.email.label`)}
              placeholder={t(`steps.billingContact.fields.email.placeholder`)}
              autocomplete="email"
              name="email"
              shrink
              data-testid="email"
            />

            <PhoneInput
              required
              margin="normal"
              fullWidth
              label={t('steps.billingContact.fields.phone.placeholder')}
              autocomplete="phone"
              name="phone"
              autoFocus
              shrink
              data-testid="phone"
            />
          </Box>
        </Box>

        <Box>
          <Button
            type="submit"
            size="lg"
            fullWidth
            data-testid="billing-info-submit-button"
            disabled={!formState.isValid}
            color="gradient"
          >
            {t('steps.billingContact.buttons.saveAndContinue')}
          </Button>
        </Box>
      </Form>
    </FormProvider>
  );
};
